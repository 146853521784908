<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="mb-3 sub-modal">
      <div class="col-12 px-0">
        <ModalActions @close="$router.go(-1)" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary">
          <!-- <i :class="$route.meta.iconClass"></i> -->
          Nouvelle vision</span
        >
        <div>
          <b-button pill @click.prevent="onSubmit">Confirmer</b-button>
        </div>
      </div>
    </div>

    <form
      id="add-page"
      class="row col-12 p-0 mt-4 mx-auto"
      @submit.prevent="onSubmit"
    >
      <div class="col-md-4 col-12 p-1">
        <div class="col-12 shdow h-100">
          <div class="w-100 m-0 p-0 text-secondary">
            <p class="h4 p-0 m-0">Informations générales</p>
          </div>
          <hr class="mt-1 mb-4 bg-secondary" />

          <b-form-group
            label="Libellé:"
            class="font-weight-bold"
            label-cols-sm="4"
            label-align-sm="left"
          >
            <b-form-input
              type="text"
              placeholder="Libellé"
              v-model="vision.libelle"
              class="font-weight-normal"
              :class="{
                'is-invalid':
                  $v.vision.libelle.$error && $v.vision.libelle.$dirty,
              }"
            ></b-form-input>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.vision.libelle.$dirty"
            >
              {{
                !$v.vision.libelle.required
                  ? "Champ obligatoire"
                  : !$v.vision.libelle.minLength
                  ? `Le champ doit contenir au moins ${$v.vision.libelle.$params.minLength.min} caractères.`
                  : ""
              }}
            </span>
            <!--  -->
          </b-form-group>

          <b-form-group
            label="Description:"
            class="font-weight-bold"
            label-cols-sm="4"
            label-align-sm="left"
          >
            <b-form-textarea
              class="form-control h-100"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Description"
              v-model="vision.description"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            label="Responsable:"
            class="font-weight-bold"
            label-cols-sm="4"
            label-align-sm="left"
          >
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
            >
              <!-- :class="{
                'is-invalid': $v.vision.responsable.$error && $v.vision.responsable.$dirty,
              }" -->
              <v-select
                :options="responsables"
                placeholder="Responsable"
                name="responsable"
                class="bg-transparent"
                label="full_name"
                v-model="vision.responsable"
                :reduce="(responsable) => `/api/users/${responsable.id}`"
              />
            </div>
            <!-- ERROR MESSAGES -->
            <!-- <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.vision.responsable.$dirty"
            >
              {{
                !$v.vision.responsable.required
                  ? "champ obligatoire"
                  : !$v.vision.responsable.minLength
                  ? `Le champ doit contenir au moins ${$v.vision.responsable.$params.minLength.min} caractères.`
                  : ""
              }}
            </span> -->
            <!--  -->
          </b-form-group>
          <b-form-group
            label="Statut:"
            class="font-weight-bold"
            label-cols-sm="4"
            label-align-sm="left"
          >
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid':
                  $v.vision.status.$error && $v.vision.status.$dirty,
              }"
            >
              <v-select
                class="bg-transparent"
                :options="statusVisions"
                placeholder="Statut"
                v-model="vision.status"
              />
            </div>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.vision.status.$dirty"
            >
              {{ !$v.vision.status.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </b-form-group>

          <b-form-group
            label="Organisation:"
            class="font-weight-bold"
            label-cols-sm="4"
            label-align-sm="left"
          >
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid':
                  $v.vision.organisation.$error &&
                  $v.vision.organisation.$dirty,
              }"
            >
              <v-select
                class="bg-transparent"
                :options="organisations"
                placeholder="Organisation Responsable"
                name="responsable"
                label="libelle"
                v-model="vision.organisation"
                :reduce="
                  (organisation) =>
                    `/api/referentiel/organisations/${organisation.id}`
                "
              />
            </div>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.vision.organisation.$dirty"
            >
              {{ !$v.vision.organisation.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </b-form-group>
        </div>
      </div>
      <div class="col-md-4 col-12 p-1">
        <div class="col-12 h-100 shdow">
          <div class="">
            <div class="w-100 d-flex m-0 p-0 text-secondary">
              <div class="w-100 m-0 p-0 text-secondary">
                <p class="h4 p-0 m-0">Avancement</p>
              </div>
            </div>
            <hr class="mt-1 mb-4 bg-secondary" />

            <b-form-group
              label="Début:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <date-picker
                placeholder="Date de début:"
                v-model="vision.startedAt"
                format="DD/MM/YYYY"
                style="padding-right: 0 !important ; border: 1px"
                class="font-weight-normal"
                :input-attr="{
                  class: {
                    'is-invalid':
                      $v.vision.startedAt.$error && $v.vision.startedAt.$dirty,
                    ' form-control': true,
                  },
                }"
              ></date-picker>

              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.vision.startedAt.$dirty"
              >
                {{ !$v.vision.startedAt.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </b-form-group>

            <b-form-group
              label="Fin:"
              class="font-weight-bold"
              label-cols-sm="4"
              label-align-sm="left"
            >
              <date-picker
                placeholder="Date de fin"
                v-model="vision.endingDate"
                format="DD/MM/YYYY"
                style="padding-right: 0 !important ; border: 1px"
                class="font-weight-normal"
                :input-attr="{
                  class: {
                    'is-invalid':
                      $v.vision.endingDate.$error &&
                      $v.vision.endingDate.$dirty,
                    ' form-control': true,
                  },
                }"
              ></date-picker>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.vision.endingDate.$dirty"
              >
                {{
                  !$v.vision.endingDate.required
                    ? "Champ obligatoire"
                    : $v.vision.endingDate.required &&
                      $v.vision.endingDate.$error
                    ? "La date de fin ne doit pas être antérieur à la date de début"
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 p-1">
        <div class="col-12 h-100 shdow">
          <div>
            <div class="w-100 d-flex m-0 p-0 text-secondary">
              <div class="w-100 m-0 p-0 text-secondary">
                <p class="h4 p-0 m-0">Couvertures</p>
              </div>
            </div>
            <hr class="mt-1 mb-4 bg-secondary" />

            <Places :testCouverture="sendingFunction" />
            <div class="row m-0 p-0">
              <div
                style="height: 50px; position: relative; overflow: visible"
                class="shdow-2 m-1"
                v-for="(covert, index) in vision.couvertures"
                :key="index"
              >
                <p>
                  <country-flag
                    :country="covert.countryCode"
                    size="small"
                    v-show="covert.countryCode"
                  />{{ covert.country }}
                </p>

                <div style="position: absolute; top: -5px; right: -5px">
                  <span style="cursor: pointer"
                    ><i
                      @click="deleteCouverture(index)"
                      class="mdi mdi-close-circle text-danger"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <hr />

    <b-tabs class="tab-solid tab-solid-primary mt-3">
      <b-tab title="Objectifs stratégiques">
        <div class="d-flex mb-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des objectifs strategiques
          </span>

          <div class="d-flex justify-content-end">
            <customAddButton
              text="Ajouter un objectif stratégique"
              :disabled="true"
            ></customAddButton>
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div class="row">
          <div class="col-12">
            <strategic-goal-list
              @showFdrListModal="showFdrList = !showFdrList"
              @removeEditableStrategicGoal="removeEditableStrategicGoal"
              :items="vision.objectifs"
              :fields="strategicGoal.tableFields"
              :responsables="responsables"
              :organisations="organisations"
              @addObjectif="addObject"
              @deleteItem="deleteItem"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Cartographie de déploiement" lazy>
        <div><MapBox :countries="[]" :vision="{ couvertures: [] }" /></div>
      </b-tab>
    </b-tabs>

    <div v-if="showFdrList" to="modals-xyz-500">
      <sub-modal @close="showFdrList = !showFdrList">
        <fdr-list />
      </sub-modal>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import MapBox from "@/components/custom/MapBox.vue";
import Places from "@/components/custom/Places.vue";
import { required, minLength } from "vuelidate/lib/validators";

import StrategicGoalList from "./components/StrategicGoalList.vue";
import SubModal from "@/components/custom/SubModal.vue";
import FdrList from "./components/FdrList.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import customAddButton from "@/components/custom/CustomAddButton";

export default {
  components: {
    CountryFlag,
    MapBox,
    StrategicGoalList,
    SubModal,
    Places,
    FdrList,
    ModalActions,
    customAddButton,
  },
  data: () => ({
    showFdrList: false,
    delete: false,
    editMode: false,
    idCouverture: [],
    submitingForm: false,
    vision: {
      libelle: null,
      description: null,
      organisation: null,
      responsable: null,
      objectifs: [],
      startedAt: "",
      status: null,
      couvertures: [],
    },
    location: {
      adresse: "",
      country: "",
      lat: "",
      lng: "",
      countryCode: "",
    },
    status: null,
    strategicGoal: {
      tableFields: [
        {
          key: "libelle",
          label: "Objectif stratégique",
          sortable: true,
        },
        { key: "description", label: "Description", sortable: true },
        {
          key: "organisationResp",
          label: "Organisation responsable",
          sortable: true,
        },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "kpis", label: "KPIs", sortable: true },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "150px !important", minWidth: "150px !important" },
        },
      ],
    },
  }),
  validations: {
    vision: {
      libelle: {
        required,
        minLength: minLength(4),
      },
      // responsable: {
      //   required,
      // },
      organisation: {
        required,
      },
      status: {
        required,
      },
      endingDate: {
        required,
        minValue(val) {
          return this.vision.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.vision.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      startedAt: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchVisionStatus");
    this.$store.dispatch("status/fetchAllStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["VISIONS_STATUS", "STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("vision", ["VISION"]), //get all organisations
    ...mapGetters(["AuthenticatedUser", "isAdmin", "isSuper"]),
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        full_name: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusVisions() {
      return this.VISIONS_STATUS;
    },
    organisations() {
      // return this.ORGANISATIONS;
      return this.isAdmin || this.isSuper
        ? this.ORGANISATIONS
        : this.AuthenticatedUser &&
          this.AuthenticatedUser.organisationRatachement
        ? [this.AuthenticatedUser.organisationRatachement]
        : [];
    },
  },
  methods: {
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      // this.vision.couvertures.push(this.clone(location));
      if (
        !this.vision.couvertures.find(
          (couverture) => couverture.countryCode === location.countryCode
        )
      )
        this.vision.couvertures.push(this.clone(location));
      else
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    test() {
      Swal.fire("La couverture est créée avec succès !", "", "success");
      this.submitingForm = false;
      this.editMode = this.editMode;
      this.$emit("leaveChange");

      this.idCouverture.forEach((element) => {
        this.$store.dispatch("couverture/deleteCouverture", element);
      });
      this.editMode = !this.editMode;
    },
    deleteCouverture(index) {
      this.idCouverture[index] = this.vision.couvertures[index].id;
      this.vision.couvertures.splice(index, 1);
    },
    addEditableStrategicGoal() {
      this.strategicGoal.tableItems.push({ editMode: true });
    },
    removeEditableStrategicGoal(itemIndex) {
      this.strategicGoal.tableItems = this.strategicGoal.tableItems.filter(
        (item, index) => itemIndex !== index
      );
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let tmpDt = {
          libelle: this.vision.libelle,
          description: this.vision.description,
          echeance: moment(this.vision.endingDate).format("DD/MM/YYYY"),
          organisation: this.vision.organisation,
          responsable: this.vision.responsable || null,
          avancement: 0,
          status: this.vision.status,
          startedAt: moment(this.vision.startedAt).format("DD/MM/YYYY"),
          endingAt: moment(this.vision.endingDate).format("DD/MM/YYYY"),
        };

        this.$store.dispatch("vision/createVision", tmpDt).then(() => {
          this.submitingForm = false;
        });
      }
    },

    addObject(objectif) {
      objectif.organisationResp = this.ORGANISATIONS.find(
        (organisation) => organisation.id === objectif.organisationResp
      );
      objectif.responsable = this.RESPONSABLES.find(
        (responsable) => responsable.id === objectif.responsable
      );
      this.vision.objectifs.unshift(objectif);
    },
    deleteItem(index) {
      this.vision.objectifs = this.vision.objectifs.filter(
        (value, i) => index != i
      );
    },
  },

  watch: {
    VISION: function () {
      Swal.fire({
        title: "La vision est bien créée !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-vision",
            params: {
              slug: this.VISION.slug || "abcd",
              id: this.VISION.id,
              fromCreation: true,
            },
          }); //redirect to the new task
        }
      });

      this.vision.couvertures.forEach((element) => {
        this.$store.dispatch("couverture/createCouverture", {
          ...element,
          vision: `/api/strategy/visions/${this.VISION.id}`,
        });
      });
    },
  },
};
</script>

<style>
.map {
  padding-top: 42%;
}

.shdow-1 {
  max-height: 50px !important;
  min-height: 50px !important;
  position: relative;
  overflow: visible;
}
.shdow-2 {
  box-shadow: 1px 2px 6px -2px;
  padding: 20px;
  border-radius: 10px;
  min-height: 1%;
}
</style>
<style scoped>
@media (min-width: 600px) {
  .align-btn-table {
    position: relative !important;
    left: -208px !important;
  }
}
</style>
